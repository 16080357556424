@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  /* --primary: #ff476f; */
  /* --primary: #f25b0a; */
  /* --secondary: #283345; */
  --primary: #fff;
  --secondary: #142055;
}

body {
  font-family: 'Roboto Mono', monospace;
}

a {
  text-decoration: underline;
}